<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main'
import TeamsService from '@src/services/TeamsService'
import teamsModal from '@views/teams/teamsModal'
import teamsFilterModal from '@views/teams/teamsFilterModal'
import GridFormModal from '@src/components/GridFormModal.vue'
import pagination from '@src/mixins/pagination'
import swalFeedback from '@src/mixins/swalFeedback'
import filterVuetable from '@src/mixins/filterVuetable'
import FilterBar from '@components/FilterBar'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import userChildrenFields from '@/src/fields/childrens/userChildrenFields.vue'
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'TEAMS'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        teamsModal,
        teamsFilterModal,
        GridFormModal,
        FilterBar,
        FilterLabelButton,
        AddLabelButton,
        CountTableButton,
        MoreTableButton,
    },
    mixins: [swalFeedback, pagination, filterVuetable, userChildrenFields],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            submitLoading: false,
            additionalParameters: {
                with: ['leader', 'users'],
            },
            modalIsEdit: false,
            modal: {
                id: 0,
                name: '',
                leader_id: null,
                leader: null,
                users: [],
            },
            data: [],
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'leader.name',
                    title: this.getI18n(i18nCommon, 'supervisor'),
                    sortField: 'users:leader_id|users.name',
                },
                {
                    name: 'users',
                    title: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    })
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            css: {},
            loadingEdit: [],
            filterText: '',
            filters: {
                leader: [],
                leader_id: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.collaborator',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: 'TEAMS',
                        suffix: 'TITLES.team',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewTeam() {
            return this.getI18n(i18nKey, 'BUTTONS.new_team')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                with: ['leader', 'users'],
                search: '',
            }
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText};leader.name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },

    methods: {
        fetch(url, params) {
            return TeamsService.fetchVuetable(url, params)
        },

        i18nTeam(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.team',
                modifier: modifier,
            })
        },

        cleanModal() {
            this.replaceModalData({
                id: 0,
                name: '',
                leader_id: null,
                leader: null,
                users: [],
            })
        },

        createAdditionalParameters() {
            return this.formatParameters({
                with: ['leader', 'users'],
                leader_id: this.filters.leader.map((leader) => leader.id),
            })
        },

        filter(appliedFilters) {
            this.filters = appliedFilters;
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() => this.$refs.teamFilterModal.showModal(this.filters))
        },


        showModal() {
            this.$nextTick(() => this.$refs.teamModal.showModal())
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        async openModalToEdit(data, index, event) {
            this.loadingEdit.push(data.id)
            this.modalIsEdit = false
            this.replaceModalData(data)
            this.showModal()
            this.loadingEdit.splice(
                this.loadingEdit.findIndex((el) => el === data.id),
                1
            )
        },

        openModalUsers(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridUsersModal.showModal())
        },

        replaceModalData(data) {
            this.modal = Object.assign(this.modal, data)

            this.modal.leader_id = this.modal.leader ? this.modal.leader.id : null

            Object.assign(this.modal, {
                users: []
            })

            Object.assign(this.modal.users, data.users)
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"/>
                    <filter-label-button @onClick="showFilterModal" />
                </div>

                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'TEAM')"
                            :title="i18nNewTeam"
                            @onClick="openModalToCreate"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nTeam(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="users" slot-scope="props">
                            <count-table-button :value="props.rowData.users.length" @onClick="openModalUsers(props.rowData)" />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <teams-modal
            ref="teamModal"
            :modal="modal"
            :modal-is-edit="modalIsEdit"
            @refresh="reloadTable"
        />
        <grid-form-modal
            ref="gridUsersModal"
            :modal="modal"
            :items="modal.users"
            :fields="userChildrenFields"
            :title="getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    })"
        />
        <teams-filter-modal
            ref="teamFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
