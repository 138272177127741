<template>
    <form-modal
        ref="formModal"
        :handle-open="resetValidation"
        :v="$v.modalTeam"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nTeam"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nTeam"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'TEAM')"
                                :title="getI18n(i18nKey, 'TITLES.team')"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'TEAM')"
                                :title="getI18n(i18nKey, 'TITLES.team')"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="teams-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv">
                    <b-tab :title="i18nInformation">
                        <b-form-row>
                            <b-col md="8">
                                <b-form-group
                                    :label="i18nName"
                                    class="required label-pdv"
                                    label-for="modal-name"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        v-model.trim="$v.modalTeam.name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :state="validateField('name', 'modalTeam')"
                                        :placeholder="i18nName"
                                        autofocus
                                        autocomplete="off"
                                        :disabled="!isEdit || submitLoading"
                                        @blur="$v.modalTeam.name.$touch"
                                        @input="clearResponseError('name', 'modalTeam')"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nSupervisor"
                                    class="required label-pdv"
                                    label-for="modal-leader"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_supervisor')"
                                >
                                    <multiSelectWithService
                                        :id="'modal-leader'"
                                        ref="leaderMultiselect"
                                        v-model="modalTeam.leader"
                                        :input-function="$v.modalTeam.leader.id.$touch"
                                        :select-class="validationClass($v.modalTeam.leader.id)"
                                        :service="'users'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="!isEdit || submitLoading"
                                        :parameters="usersParameters"
                                        @input="changeLeaderId"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-tab>

                    <b-tab :title="i18nUser">
                        <b-form-row>
                            <b-col v-if="isEdit" class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="getI18n('USERS.TITLES.user')"
                                    label-for="add-user-input"
                                    class="label-pdv" >
                                    <multiSelectWithService
                                        :id="'add-user-input'"
                                        ref="userMultiselect"
                                        v-model="userName"
                                        :service="'users'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                        :parameters="usersParameters"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col :class="isEdit ? 'col-sm-12 col-md-8' : 'col-sm-12 col-md-12'">
                                <data-mode-vuetable
                                    ref="relationVuetable"
                                    v-model="modalTeam.users"
                                    :fields="fields"
                                    :display-delete-button="isEdit"
                                    :disabled="submitLoading"
                                    @onClickDelete="handleRemoveUser"
                                />
                            </b-col>
                        </b-form-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="tabIndex === 0"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex = 1"
                />
                <save-button
                    v-else-if="isTeamCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import {
    required,
    minLength,
    maxLength,
    numeric,
} from 'vuelidate/lib/validators'

import TeamsService from '@src/services/TeamsService'

import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import multiSelectWithService from '@components/multiSelectWithService'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'TEAMS'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        multiSelectWithService,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
        DataModeVuetable
    },
    mixins: [validationMixin, swalFeedback],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    name: '',
                    leader_id: null,
                    leader: null,
                    users: [],
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalTeam: this.modal,
            users: [],
            filteredUsers: [],
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            userName: '',
            tabIndex: 0,
            fields: [
                {
                    key: 'name',
                    label: this.getI18n('COMMON.user_name'),
                    sortable: true,
                },
                {
                    key: 'email',
                    label: this.getI18n('COMMON.email'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        modalTeam: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },

            leader: {
                id: {
                    required,
                    numeric,
                },
            },

            users: {
                required,
                minLength: minLength(1),
                $each: {
                    id: {
                        required,
                        numeric,
                    },
                },
            },
        },
    },

    computed: {
        isTeamCreated() {
            return this.modalTeam.id > 0
        },
        i18nTitleModal() {
            if (this.isTeamCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nTeam}`
                }
                return this.modalTeam.name
            }
            else {
                return this.i18nNewTeam
            }
        },
        i18nTeam() {
            return this.getI18n(i18nKey, 'TITLES.team')
        },
        i18nNewTeam() {
            return this.getI18n(i18nKey, 'BUTTONS.new_team')
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nSupervisor() {
            return this.getI18n(i18nCommon, 'supervisor')
        },
        i18nUser() {
            return this.getI18nModified({
                prefix: 'USERS.TITLES.user',
                modifier: 2,
            })
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'info',
                modifier: 2,
            })
        },
        modalOperation() {
            return this.isTeamCreated ? 'edited' : 'created'
        },
        selectedUsers() {
            return this.modalTeam.users
        },
    },

    watch: {
        userName(value) {
            if (value) {
                const index = this.modalTeam.users.findIndex(find => find.id === value.id);
                if (value.id === this.modalTeam.leader_id) {
                    this.warningFeedback(this.i18nTeam, this.getI18n(this.i18nKey, 'MESSAGES.it_is_not_allowed_to_link_the_team_supervisor'))
                }
                else if (index < 0) {
                    this.modalTeam.users.push(value)
                }
                this.userName = ''
                this.$refs.userMultiselect.$el.focus()
            }
        },
        'modalTeam.leader_id': function (newValue) {
            this.filteredUsers = this.users.filter(
                (user) => user.id !== newValue
            )
            this.modalTeam.users = this.modalTeam.users.filter(
                (user) => user.id !== newValue
            )
        },
    },
    methods: {
        changeLeaderId(e) {
            this.modalTeam.leader_id = e ? e.id : null
            this.clearResponseError('leader', 'modalTeam')
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.submitLoading = false
            this.deleteLoading = false
            this.modalTeam = this.modal
            this.userName = ''
            this.tabIndex = 0
            this.$refs.formModal.show()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            this.replaceModalData({
                id: '',
                name: '',
                leader_id: null,
                leader: null,
                users: [],
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalTeam, data)
        },

        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },

        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.modalTeam.$touch()
            if (!this.$v.modalTeam.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const formattedTeam = Object.assign({}, this.modalTeam)

            formattedTeam.users =
                this.modalTeam.users.map((user) => user.id) || []

            const operation = this.isTeamCreated
                ? TeamsService.update(this.modalTeam.id, formattedTeam)
                : TeamsService.create(formattedTeam)

            const createResponse = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nTeam, this.modalOperation)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = createResponse.data.errors
            }
            this.submitLoading = false
        },

        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteTeam)
        },

        async deleteTeam(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.modalTeam.$touch()
            this.deleteLoading = true
            const response = await TeamsService.delete(this.modalTeam.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nTeam, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },

        async handleRemoveUser(id) {
            const index = this.modalTeam.users.findIndex(find => find.id === id);

            if (index > -1) {
                this.modalTeam.users.splice(index, 1);
            }
        },
    },
}
</script>
